// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "m_qh d_bD";
export var storyRowWrapper = "m_hx d_hx d_bK";
export var storyLeftWrapper = "m_qj d_bz d_bP";
export var storyWrapperFull = "m_qk d_cD";
export var storyWrapperFullLeft = "m_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "m_mv d_hy";
export var storyLeftWrapperCenter = "m_ql d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "m_qm d_hF";
export var storyHeader = "m_qn d_hD d_w d_cs";
export var storyHeaderCenter = "m_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "m_hB d_hB d_by d_dw";
export var storyBtnWrapper = "m_qp d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "m_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "m_qq d_fg d_Z";
export var imageWrapperFull = "m_qr d_w d_H d_bf d_Z";
export var SubtitleSmall = "m_qd p_qd p_qt p_qG";
export var SubtitleNormal = "m_qf p_qf p_qt p_qH";
export var SubtitleLarge = "m_qg p_qg p_qt p_qJ";
export var textLeft = "m_dv";
export var textCenter = "m_dw";
export var textRight = "m_dx";