// extracted by mini-css-extract-plugin
export var lbContainerOuter = "t_s7";
export var lbContainerInner = "t_s8";
export var movingForwards = "t_s9";
export var movingForwardsOther = "t_tb";
export var movingBackwards = "t_tc";
export var movingBackwardsOther = "t_td";
export var lbImage = "t_tf";
export var lbOtherImage = "t_tg";
export var prevButton = "t_th";
export var nextButton = "t_tj";
export var closing = "t_tk";
export var appear = "t_tl";